import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'


const StyledLink = styled.div`
    text-decoration: none;
    position: relative;
    font-size: 1rem;
    overflow: hidden;
    padding: ${({ theme }) => `calc(${theme.paddingBox} / 4) calc(${theme.paddingBox} / 2)`};
    span {
        display: block;
        color:${({ theme }) => theme.colorText};
        letter-spacing: 2px;
        transform: translate3d(0px, 0px, 0);
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.5rem;
    }
`

export default ({ to, name, title }) => (
    <Link activeStyle={{  }} to={to}>
        <StyledLink title={title}>
            <span title={name}>{name}</span>
        </StyledLink>
    </Link>
)