import React from 'react'
import styled from 'styled-components'
import Spliter from '@lib/simba/Spliter'

const Container = styled.div`
    text-transform: uppercase;
`

const StyledLink = styled.a`
    color: ${({ theme }) => theme.footer.colorLink};
    margin-right: ${({ theme }) => theme.paddingBox};
    display: inline-block;
`

export default ({ data }) => (
    <Container>
        {data.social.map(({ url, name }) => <StyledLink key={url} href={url} title={name}>{name}</StyledLink>)}
        {[data.mobile, data.phone].filter(t => t !== null).map(phone => (
            <StyledLink key={phone} title={phone} href={`tel:${phone.replace(/\s/gi, '')}`}><Spliter s={phone} /></StyledLink>
        ))}
    </Container>
)