import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Container = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.backgroundLogo};
`

export default ({ alt, img }) => (
    <Container>
        <Img
            fixed={img}
            alt={alt}
        />
    </Container>
)