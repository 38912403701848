import React from "react"
import styled from "styled-components"
import { SocialIcon } from "react-social-icons"

const Container = styled.section`
  font-size: 5vw;
  line-height: 1.2;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    text-align: right;
    font-size: 1.5vw;
  }
`
const Title = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2.5vw;
  }
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.desktop}) {
    font-size: 1.2vw;
  }
`

const Mobile = styled.div`
  font-weight: bold;
  margin-top: 10px;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    font-size: 2vw;
  }
`

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    justify-content: flex-end;
  }
`

const Social = styled.div`
  padding-right: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-left: calc(${({ theme }) => theme.paddingBox}/2);
    padding-right: inherit;
  }
`

const CardFooter = ({  accroche, social }) => (
  <Container>
    <Title>{accroche}</Title>
    <SocialContainer>
      {social.map(({ url }) => (
        <Social key={url}>
          <SocialIcon url={url} bgColor='white' />
        </Social>
      ))}
    </SocialContainer>
  </Container>
)

export default CardFooter
