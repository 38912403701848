const sanitizeUrl = fragment => fragment.toLowerCase().replace(/\s/g, '').replace(/[^a-z0-9-]/g, '-')

export default (type, fragment) => {
    switch(type) {
        case 'ARTICLE':
            return `/article-${fragment}`
        case 'PRODUCT':
            const url = `${fragment[0].replace('price_', '')}-${fragment[1]}`.toLowerCase().replace(/\s/g, '').replace(/[^a-z0-9-]/g, '-')
            return `/${url}`
        case 'PRODUCT_OBJECT':
            return `/${sanitizeUrl(`${fragment.codeProduit}-${fragment.nom}`)}`
        default:
            return `/${fragment}${type === 'SanityContenu' ? '' : '/1'}`
    }
}