import React from 'react'

const EffectBox = ({ effect, duration, delay, children }) => (
    <div
        data-sal={effect}
        data-sal-duration={duration ? duration : 200}
        data-sal-delay={delay ? delay : 0}
    >
        {children}
    </div>
)

export default EffectBox