import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import ResponsiveMenu from './ResponsiveMenu'
import Img from 'gatsby-image'

const Container = styled.div` 
    background: transparent;
    padding: 0 ${({ theme }) => theme.paddingBox};
    width: 100vw;
    max-width: 100vw;
    height: 150px;
    margin: auto;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`


const Element = ({ links, name, logo, linkContact }) => (
    <Container >
        <ResponsiveMenu logo={logo} links={links} linkContact={linkContact}/>
    </Container>
)

Element.defaultProps = {
    links: [],
    front: false
}

Element.propTypes = {
    links: PropTypes.array,
    front: PropTypes.bool
}

export default Element
