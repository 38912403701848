import React from 'react'
import styled from 'styled-components'


const Span = styled.span`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        white-space: nowrap;
    }
    white-space: wrap;
`
const Word = styled.span`
    font-weight: ${({ weight }) => weight < 1 ? 'bolder' : (weight < 3 ? 'normal' : 'normal')};
`

export default ({ s }) => (
    <Span>
        {s.split(' ').map((w, index) => <Word key={index} weight={index}>{w} </Word>)}
    </Span>
)