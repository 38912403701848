import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import MenuOne from '@lib/simba/menu-one'
import { HamburgerSpin } from "react-animated-burgers"
import createLink from '@utils/create-link'

const Head = styled.header`
  display: flex;
  with: 100vw;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
`
const LogoContainer = styled.div`
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:5000;
`
const Logo = styled(Img)`
  position: absolute;
  top: 0; left: 0px;
  margin:auto;
  height: 100%;
  width: 100%;
  z-index: 5000 ;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    top: 0; left: 5vw;
  }
`

export default ({  logo, name, links, linkContact }) => (
  <Head>
    <Link to="/">
      <LogoContainer>
        <Logo
            fixed={logo.asset.fixed}
            alt=""
        />
      </LogoContainer>
    </Link>
    <MenuOne 
      logo={logo}
      alt={name} 
      linkContact={linkContact.filter(link => link.slug !== null).map(({ __typename, slug, title }) => ({
        to: createLink(__typename, slug.current),
        title,
        name: title
      }))}
      links={links.filter(link => link.slug !== null).map(({ __typename, slug, title }) => ({
      to: createLink(__typename, slug.current),
      title,
      name: title
      }))}></MenuOne>
  </Head>
)
