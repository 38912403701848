import styled from 'styled-components'

export default styled.div`
    position: relative;
    z-index: 2;
    transform: ${({ isOpen }) => isOpen ? `translate3d(-80vw,0,0)` : `translate3d(0,0,0)`};
    transition: all 500ms cubic-bezier(0.550, 0.055, 0.220, 1.245); /* custom */
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        transform: ${({ isOpen }) => isOpen ? `translate3d(-350px,0,0)` : `translate3d(0,0,0)`};
    }
`