import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import BlockContent from '@sanity/block-content-to-react'

const Footer = styled.div`
  padding: 0 ${({ theme }) => theme.paddingBox};
  color: ${({ theme }) => theme.colorText};
  a {
    color: ${({ theme }) => theme.colorText};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  } 
`
const InsideFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center; 
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 ${({ theme }) => theme.paddingBox};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    justify-content: space-between;
    width: 100vw;
  }
`

const LogoContainer = styled.div`
  height: 225px;
  width: 150px;
  display: flex;
  margin: auto;
  z-index: 5000;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    margin: 0;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

`
const Logo = styled(Img)`

height: 100%;
width: 100%;
z-index: 5000 ;
@media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    position: absolute;
    top: 0; left: 5vw;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-bottom: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 calc(${({ theme }) => theme.paddingBox}*2);
    max-width: 50%;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-bottom: 0rem;
    justify-content: center;
    max-width: 40%;
    font-size: 1.5rem;
  }
`

const MenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.paddingBox};
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.desktop}) {
    padding-left: 0rem;
    padding-bottom: 0rem;
    align-items: center;
    justify-content: center;
  }
`
const Links = styled.ul`
  display: flex;
  flex-direction: column;
  margin : 0;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
  }
`
const BottomLink = styled(Link)`
  display: block;
  font-size: 1.5rem;
  margin: calc(${({ theme }) => theme.paddingBox}/4) 0;
`
const Signature = styled.div`
  width:100%;
  margin:auto;
  text-align:center;
  a {
    color: #0f5584;
  }
  @media only screen and (min-width: ${({ theme }) =>theme.breakpoints.tablet}) {
    width: inherit;
    margin: inherit;
    text-align: end;
  }
`
const FooterLinks = styled.div`
  display: flex;
  color: ${({ theme }) =>theme.colors.secondary};
  a {
    color: ${({ theme }) =>theme.colors.secondary};
  }
  margin: calc(${({ theme }) => theme.paddingBox}/2) 0;
`
const Span = styled.span`
  margin: 0 calc(${({ theme }) => theme.paddingBox}/2);
  &:last-child{
    display: none;
  }
`
const Mentions = styled.div`
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1.3rem;
  }
`
const Copyright = styled.p`
  color: grey;
  margin: calc${({ theme }) => theme.paddingBox}/2) 0;
`
const SimpleFooter = ({  logo, data, links, mentions}) => (
<Footer >
    <InsideFooter>
    <LogoContainer>
        <Logo
          fixed={logo.asset.fixed}
          alt="Pierre Segond Assurance"
        />
      </LogoContainer>
        <MenuFooter>
            <Links>
            {links.map(({ id, slug, title }) => (
                <BottomLink key={id} to={`/${slug.current}`}>
                {title}
                </BottomLink>
            ))}
            </Links>
        </MenuFooter>
        <TextContainer>
            <Mentions><BlockContent blocks={data._rawContent} /></Mentions>
            <FooterLinks>
              {mentions.map(({ id, slug, title }) => (
                <>
                  <Link key={id} to={`/${slug.current}`}>
                  {title}
                  </Link> 
                  <Span>|</Span>
                </>
              ))}
            </FooterLinks>
            <Copyright>{data.copyright}</Copyright>
        </TextContainer>
    </InsideFooter>
    <Signature>
        <a
          href="https://www.jackandrussells.com"
          rel="nofollow"
          title="agence web toulon"
        >
          Ce site est ultra-rapide grâce à jack.
        </a>
    </Signature>
</Footer>
)

export default SimpleFooter
