import React from "react"
import styled from "styled-components"

const InnerStructure = styled.div`
  overflow-x: hidden;
`

export default ({ opened, children }) => (
  <section>
    <InnerStructure isOpen={opened}>{children}</InnerStructure>
  </section>
)
