import React, { useState, useCallback, useEffect } from "react"
import { Structure, Sidebar, Main } from "@lib/simba/structure"
import { Header } from "@lib/simba/ecommerce"
import { SimpleFooter } from "@lib/simba/footer"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "@lib/simba/menu"

export default ({ children, hideFooterArticle, isHome }) => {
  const [opened, setOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query LogoIdentity {
      littleLogo: sanityIdentity {
        logo {
          asset {
            fixed(height: 175, width: 100) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
      footerLogo: sanityIdentity {
        logo {
          asset {
            fixed(height: 250, width: 150) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
      sanityIdentity {
        logo {
          asset {
            fluid(maxHeight: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        email
        mobile
        name
        address
        accroche
        mentions
        postalcode
        city
        copyright
        _rawContent
      }
      menuFooter: sanityMenu(slug: { current: { eq: "footer" } }) {
        ...MenuElements
      }
      menuPrincipal: sanityMenu(slug: { current: { eq: "principal" } }) {
        ...MenuElements
      }
      menuContact: sanityMenu(slug: { current: { eq: "contact" } }) {
        ...MenuElements
      }
      menuMentions: sanityMenu(slug: { current: { eq: "mentions" } }) {
        ...MenuElements
      }
      allSanityContenu(
        limit: 1
        sort: { fields: publishdate }
        filter: {
          categories: { elemMatch: { slug: { current: { eq: "actualites" } } } }
        }
      ) {
        nodes {
          slug {
            current
          }
          resume
          title
          cover {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `)


  return (
    <>
      <Structure opened={opened}>
        <Sidebar isOpen={opened}>
          <Menu linksData={data.menuPrincipal.links} linkContact={data.menuContact.links} />
        </Sidebar>
        <Main isOpen={opened} isHome={isHome}>
          <Header
            logo={data.littleLogo.logo}
            name={data.sanityIdentity.name}
            links={data.menuPrincipal.links}
            linkContact={data.menuContact.links}
          />
          {children}
          <SimpleFooter
            logo={data.footerLogo.logo}
            data={data.sanityIdentity}
            links={data.menuFooter.links}
            mentions={data.menuMentions.links}
          />
        </Main>
      </Structure>
    </>
  )
}