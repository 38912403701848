import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LargeContainer = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
`

const Container = styled.div`
    width: 100%
    background: ${({ theme }) => theme.colorLink};
    display: flex;
    padding: ${({ theme }) => theme.paddingBox};
    flex-direction: column;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: ${({ theme }) => theme.breakpoints.desktop};
        margin: auto;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
    }
`
const El = styled.div`
    color: ${({ theme }) => theme.color};
    margin: 0 calc(${({ theme }) => theme.paddingBox} / 2);
`

const fields = {
    address1: ['address'],
    address2: ['city', 'postalcode'],
    emails: ['email'],
    contacts: ['phone']
}

const Address = styled(El)``
const Emails = styled(El)``
const Contacts = styled(El)``

const Title = styled.h4`
    text-transform: uppercase;
`
const Line = styled.div`
`

const InLink = styled.a`
    color: ${({ theme }) => theme.colorLink};
`

const getLine = data => fields => fields.map(f => data[f]).filter(e => e !== undefined)

const Coords = ({ data }) => (
    <LargeContainer>
        <Container>
            <Address>
                <Title>Notre adresse</Title>
                <Line>{getLine(data)(fields.address1).join(', ')}</Line>
                <Line>{getLine(data)(fields.address2).join(', ')}</Line>
            </Address>
            <Emails>
                <Title>Nous écrire</Title>
                {getLine(data)(fields.emails).map(val => <Line><InLink href={`mailto:${val}`}>{val}</InLink></Line>)}
            </Emails>
            <Contacts>
                <Title>Nous contacter</Title>
                {getLine(data)(fields.contacts).map(val => <Line>{val}</Line>)}
            </Contacts>
        </Container>
    </LargeContainer>
)

Coords.propTypes = {
    data: PropTypes.array
}


export default Coords