import React from 'react'
import styled from 'styled-components'
import { Header } from '@lib/simba/_statics'
import BackgroundImage from 'gatsby-background-image'

const HeaderArticle = styled.div`
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.paddingBox};
`

const StyledBackground = styled(BackgroundImage)`
  &::before,
  &::after {
    filter: brightness(0.5);
  }
`

export default ({ img, title }) => (
    <>
        <Header front={false} />
        <StyledBackground
            fluid={img}
        >
            <HeaderArticle />
        </StyledBackground>
    </>
)