import React, { useState } from 'react'
import { HamburgerElastic } from 'react-animated-burgers'
import MenuElement from './MenuElement'
import styled from 'styled-components'
import theme from '@utils/theme'
import { Link } from "gatsby"

const Container = styled.section`
    position: fixed;
    top: 0; right: 0;
    z-index: 8000;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        left: 0;
        position: relative;
    }
`

const BurgerContainer = styled.div`
    position: absolute:
    right:0; top:0;
    display: flex;
    justify-content: flex-end;
    width:100%;
    z-index:1;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        display: none;
    }
`

const Links = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    transform: ${({ open }) => open ? 'translate3d(0, 0, 0)' : 'translate3d(100%, 0, 0)'};
    transition: all ease-out 0.2s;
    height: 100%;
    padding: ${({ theme }) => theme.paddingBox};
    padding-left: calc(${({ theme }) => theme.paddingBox}/2);
    background: white;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
        transform: none;
        padding: 0;
        justify-content: center;
        background: transparent;
    }
`
const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    z-index:9999;
    text-transform: uppercase;
    
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        flex-direction: row;
    }
`
const LinkContact = styled(Link)`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    transition: all ease-out 0.2s;
    height: 100%;
    color: white;
    padding: 7px 1rem;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 1rem;
    font-weight: bold;
    @media only screen and (min-width: 1400px) {
        margin-left: 2rem;
        transform: ${({ open }) => open ? 'translate3d(0, 0, 0)' : 'translate3d(0, 0, 0)'};
        font-size: 1.5rem;
    }
`

export default ({ links, linkContact }) => {
    const [open, setOpen] = useState(false)
    return (
        <Container>
            <BurgerContainer>
                <HamburgerElastic
                    isActive={open}
                    toggleButton={() => setOpen(!open)}
                    barColor='#E7511E'
                    buttonStyle={{outline: 'none'}}
                />
            </BurgerContainer>
            <LinksContainer>
                <Links open={open}>{links.map(link => <MenuElement key={link.to} {...link} />)}</Links>
                <Links open={open}>{linkContact.map(link => <LinkContact key={link.to} {...link}>{link.name}</LinkContact>)}</Links>
            </LinksContainer>
        </Container>
    )
}