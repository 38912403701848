import React from 'react'
import styled from 'styled-components'
import Divbox from '@lib/simba/divbox'
import Pulse from 'react-reveal/Pulse'

const Container = styled.section`
    padding: ${({ theme }) =>theme.paddingBox};
    margin: calc(${({ theme }) =>theme.paddingBox} / 2);
    background: ${({ theme }) =>theme.header.background};
    color: ${({ theme }) => theme.header.colorText};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        width: ${({ theme }) =>theme.maxWidth};
        margin: auto;
    }
`

const Title = styled.h1`
    margin: ${({ theme }) =>theme.paddingBox};
    padding: 0;
    text-align: center;    
`

const Resume = styled.div`
    text-align: center;
`

export default ({ resume, title, coords }) => (resume !== undefined || title !== undefined) ? (
    <Divbox>
        <Container>
            {resume ? <Resume>{resume}</Resume> : null}
            {title ? <Pulse><Title>{title}</Title></Pulse> : null}
        </Container>
    </Divbox>
) : null