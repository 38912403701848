import styled from 'styled-components'

export default styled.div`
    background: ${({ theme }) => theme.menu.background};
    height: 100vh;
    position: fixed;
    right:-80vw;
    width: 80vw;
    transform: ${({ isOpen }) => isOpen ? `translate3d(-80vw,0,0)` : `translate3d(0,0,0)`};
    transition: all 400ms cubic-bezier(0.250, 0.460, 0.450, 0.940); /* easeOutQuad */
    z-index: 1;
    box-shadow: inset -10px 0 20px rgba(0, 0, 0, .2);
    padding: ${({ theme }) => theme.paddingBox};
    img {
        max-width: 100%;
        object-position: center;
    }
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        right:-350px;
        width: 350px;
        transform: ${({ isOpen }) => isOpen ? `translate3d(-350px,0,0)` : `translate3d(0,0,0)`};
    }
`