import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

const Container = styled.div`
  color: white;
  height: 100%;
  display: flex;
  padding-top: 80px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`

const StyledLink = styled(Link)`
  display: block;
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
  font-size: 4vw;
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    font-size: 1vw;
  }
`

const getLink = (type, slug) =>
  type === "SanityCategory" ? `/${slug.current}/1` : `/${slug.current}`

const Menu = ({ linksData }) => (
  <Container>
    {linksData.map(link => (
      <StyledLink key={link.id} to={getLink(link.__typename, link.slug)} activeStyle={{color: "#EC730E" }}>
        {link.title}
      </StyledLink>
    ))}
  </Container>
)

export const query = graphql`
  fragment MenuElements on SanityMenu {
    links {
      ... on SanityContenu {
        __typename
        id
        slug {
          current
        }
        title
      }
      ... on SanityCategory {
        __typename
        id
        slug {
          current
        }
        title
      }
    }
  }
`

export default Menu