import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import { Link } from "gatsby"
import CardFooter from "./CardFooter"

const Container = styled.footer`
  background: transparent;
  padding-top: ${({ up, theme }) => (up ? 0 : `calc(${theme.paddingBox}*4)`)};
`

const ArticleContainer = styled.div`
  display: block;
  padding: ${({ theme }) => theme.paddingBox};
  color: ${({ theme }) => theme.colorText};
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    max-width: ${({ theme }) => theme.maxWidth};
    margin: auto;
  }
`

const Title = styled.h1`
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 4px;
`
const Resume = styled.div`
  font-size: 1.2rem;
`

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100%;
  height: 500px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
`

const Infos = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.75);
  color: ${({ theme }) => theme.colorText};
  padding: ${({ theme }) => theme.paddingBox};
  margin: ${({ theme }) => theme.paddingBox} 0;
`

const Footer = styled.div`
  padding: ${({ theme }) => theme.paddingBox};
  background: #f6f6f6;
  margin-top: ${({ up }) => (up ? `0` : "-200px")};
  padding-top: ${({ up, theme }) =>
    up ? 0 : `calc(180px + ${theme.paddingBox})`};
`

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: darken;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    justify-content: flex-start;
  }
`

const InsideFooter = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${({ theme }) => theme.paddingBox};
  }
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    justify-content: flex-end;
  }
`

const Signature = styled.div`
  border-top: 1px solid #ddd;
  padding: ${({ theme }) => theme.paddingBox};
  margin-top: ${({ theme }) => theme.paddingBox};
  text-align: center;
  a {
    color: #a0a0a0;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: auto;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }
`

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.desktop}) {
    flex-direction: row;
  }
`
const BottomLink = styled(Link)`
  color: #a0a0a0;
  display: block;
  margin-right: calc(${({ theme }) => theme.paddingBox} / 2);
`

const BigFooter = ({ article, logo, hideFooterArticle, data, links }) => (
  <Container up={hideFooterArticle}>
    {!hideFooterArticle && (
      <Link to={`/${article.slug.current}`}>
        <ArticleContainer>
          <StyledBackgroundImage
            Tag="section"
            fluid={article.cover.asset.fluid}
          >
            <Infos>
              <Title>{article.title}</Title>
              <Resume>{article.resume}</Resume>
            </Infos>
          </StyledBackgroundImage>
        </ArticleContainer>
      </Link>
    )}
    <Footer up={hideFooterArticle}>
      <InsideFooter>
        <ImgContainer>
          <Img fixed={logo.asset.fixed} alt="Pierre Segond Assurance" />
        </ImgContainer>
        <CardContainer>
          <CardFooter {...data} />
        </CardContainer>
      </InsideFooter>
      <Signature>
        <Links>
          {links.map(({ id, slug, title }) => (
            <BottomLink key={id} to={`/${slug.current}`}>
              {title}
            </BottomLink>
          ))}
        </Links>
        <a
          href="https://www.jackandrussells.com"
          rel="nofollow"
          title="agence web toulon"
        >
          Ce site est ultra-rapide grâce à jack.
        </a>
      </Signature>
    </Footer>
  </Container>
)

export default BigFooter
