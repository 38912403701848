import React from 'react'
import styled from 'styled-components'
import MenuOne from '@lib/simba/menu-one'
import RoundedLogo from '@lib/simba/rounded-logo'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import Divbox, { DivboxCenter } from '@lib/simba/divbox'
import Presentation from './Presentation'
import { Parallax } from 'react-scroll-parallax'

const HeaderSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: ${({ theme }) => theme.header.justify};
    padding-top: calc(${({ theme }) => theme.paddingBox} * 4);
`

const Header = ({ links, name, logo, data, front }) =>  {
  return front ? (
    <Divbox>
        <MenuOne alt={name} img={logo} links={links} front={front} />
        <BackgroundImage
            Tag='section'
            fluid={data.cover.asset.fluid}
        >
          
          <HeaderSection>
            <Parallax y={[-100, 20]}>
              <DivboxCenter><RoundedLogo alt={name} img={logo} /></DivboxCenter>
              </Parallax>
            <Parallax y={[20, -20]}>
              <Divbox>
                <Presentation {...data} />
              </Divbox>
            </Parallax>
          </HeaderSection>
        </BackgroundImage>
    </Divbox>
) : <>
      <MenuOne alt={name} logo={logo} links={links} front={front} />
    </>
}

Header.defaultProps = {
    links: []
}

Header.propTypes = {
    links: PropTypes.array,
    name: PropTypes.string
}


export default Header